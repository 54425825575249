import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkeGuidesGamemodesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Game modes overview</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_modes.png"
            alt="Game modes"
          />
        </div>
        <div className="page-details">
          <h1>Game modes</h1>
          <h2>Available game modes in Goddess of Victory: NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
      <div className="page-content">
        <SectionHeader title="Game modes" />
        <p>
          Goddess of Victory: NIKKE, despite being an idle game has quite a lot
          of game modes already available. Majority of them are limited by daily
          entries, obviously, but still there is a big variety in the modes, so
          you won't get bored easily.
        </p>
        <h5>Campaign</h5>
        <StaticImage
          src="../../../images/nikke/generic/guide_story.jpg"
          alt="Story Map"
        />
        <p>
          The Campaign is split into Chapters, and each chapter is split into
          multiple stages. This mode tracks your main progress through the game.
        </p>
        <p>Learn more about the Campaign in our dedicated guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Campaign"
            link="/nikke/guides/game-modes-campaign"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_story.png"
                alt="Campaign"
              />
            }
          />
        </Row>
        <h5>Simulation Room</h5>
        <StaticImage
          src="../../../images/nikke/generic/sim_2.jpg"
          alt="Simulation"
        />
        <p>
          A staple in idle games - the labyrinth mode where you have to conquer
          stages that increase in difficulty till you find the boss and kill it.
          To make it easier, each cleared stage allows you to pick one of three
          buffs that make your team stronger.
        </p>
        <p>This mode is the main source of skill books in the game.</p>
        <p>Learn more about the Simulation Room in our dedicated guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Simulation Room"
            link="/nikke/guides/game-modes-simulation"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_sim.png"
                alt="Simulation Room"
              />
            }
          />
        </Row>
        <h5>Interception</h5>
        <StaticImage
          src="../../../images/nikke/generic/guide_interception.jpg"
          alt="Interception"
        />
        <p>
          You are alone against a boss. Only one will survive the encounter.
          Interception is the boss mode in NIKKE - the more damage you will do,
          the better rewards.
        </p>
        <p>This mode is the main source of equipment in the game.</p>
        <p>Learn more about Interception in our dedicated guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Interception"
            link="/nikke/guides/game-modes-interception"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_interception.png"
                alt="Interception"
              />
            }
          />
        </Row>
        <h5>Tribe Towers</h5>
        <StaticImage
          src="../../../images/nikke/generic/guide_tribe.jpg"
          alt="Tribe Towers"
        />
        <p>
          The good old Tower mode - climb the tower, fight stronger and stronger
          enemies and get better loot. In addition to generic Tower, there are
          also manufacturer Towers where you are restricted who you can use.
        </p>
        <p>This mode gives a variety of rewards, including character shards.</p>
        <p>Learn more about Tribe Towers in our dedicated guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tribe Towers"
            link="/nikke/guides/game-modes-tribe-towers"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_tribe.png"
                alt="Tribe Towers"
              />
            }
          />
        </Row>
        <h5>Lost Sector</h5>
        <StaticImage
          src="../../../images/nikke/generic/lost_1.jpg"
          alt="Lost Sector"
        />
        <p>
          Adventure puzzle mode where the main goal is to kill all enemies and
          find all treasures hidden on the map. The rewards are fixed here and
          you unlock new maps as you progress in the story mode.
        </p>
        <p>This mode gives a variety of rewards.</p>
        <p>Learn more about Lost Sector in our dedicated guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Lost Sector"
            link="/nikke/guides/game-modes-lost-sector"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_lost.png"
                alt="Lost Sector"
              />
            }
          />
        </Row>
        <h5>Coordinated Operation</h5>
        <p>
          This mode is basically Interception, but instead of fighting against
          the boss alone, you do it with others. Important! Each of your friends
          can only use the character he brings into this mode. Good coordination
          is key to victory.
        </p>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesGamemodesPage;

export const Head: React.FC = () => (
  <Seo
    title="Game modes | NIKKE | Prydwen Institute"
    description="Available game modes in Goddess of Victory: NIKKE."
  />
);
